import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import type { ErrorType } from "src/types/error";
import { useAxios } from "../use-axios";
import { AdminUserType } from "src/types/admin-user.type";

type CreateAdminUserRequestPayload = {
  email: string;
  password: string;
};

export const useCreateAdminUser = () => {
  const { post } = useAxios();

  return useMutation<
    AdminUserType,
    AxiosError<ErrorType>,
    CreateAdminUserRequestPayload
  >(
    async (payload: CreateAdminUserRequestPayload) =>
      await post<CreateAdminUserRequestPayload, AdminUserType>(
        "/admin/admin-user/create",
        payload,
      ),
  );
};
