import { useQuery } from "@tanstack/react-query";

import { useAxios } from "../use-axios";
import config from "../config";
import { ErrorLogType } from "src/types/error.type";

type GetContactRequest = {
  filters?: {
    query?: string;
  };
  page?: number;
  rowsPerPage?: number;
};

type GetContactResponse = {
  data: ErrorLogType[];
  count: number;
};

export const useQueryErrorLogs = (request: GetContactRequest) => {
  const { get } = useAxios();

  return useQuery({
    queryKey: ["errorLogs", request],
    queryFn: async () => {
      const params = {
        email: request.filters?.query,
        size: request.rowsPerPage,
        page: request.page,
      };

      return await get<GetContactResponse>("/admin/error-logs", {
        params,
      });
    },
    staleTime: config.staleTime,
  });
};
