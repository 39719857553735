import { lazy, Suspense } from "react";
import { RouteObject } from "react-router";
import { Outlet } from "react-router-dom";

import { Layout as DashboardLayout } from "src/layouts/dashboard";
import { AuthGuard } from "../guard/auth-guard";

const IndexPage = lazy(() => import("src/pages/index"));

// Contact people
const ContactPeoplePage = lazy(
  () => import("src/pages/dashboard/contactPeople/list"),
);

// Contact
const ContactPage = lazy(() => import("src/pages/dashboard/contact/list"));

// Admin users
const AdminUsersListPage = lazy(
  () => import("src/pages/dashboard/adminUser/list"),
);
const AdminUserCreatePage = lazy(
  () => import("src/pages/dashboard/adminUser/create"),
);

// Lots
const LotsListPage = lazy(() => import("src/pages/dashboard/lots/list"));
const LotDetailPage = lazy(() => import("src/pages/dashboard/lots/lots-detail"));

// Bills
const BillListPage = lazy(() => import("src/pages/dashboard/bills/list"));

// Inspections
const InspectionListPage = lazy(
  () => import("src/pages/dashboard/inspections/list"),
);

// Inspection reports
const InspectionReportsListPage = lazy(
  () => import("src/pages/dashboard/inspectionReport/list"),
);

// Appraisals
const AppraisalListPage = lazy(
  () => import("src/pages/dashboard/appraisals/list"),
);

// Verification code
const CodeListPage = lazy(() => import("src/pages/dashboard/code/list"));

// Error logs
const ErrorLogListPage = lazy(() => import("src/pages/dashboard/errors/list"));

export const dashboardRoutes: RouteObject[] = [
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />,
      },
      {
        path: "contactPeople",
        children: [
          {
            index: true,
            element: <ContactPeoplePage />,
          },
        ],
      },
      {
        path: "contacts",
        children: [
          {
            index: true,
            element: <ContactPage />,
          },
        ],
      },
      {
        path: "adminUsers",
        children: [
          {
            index: true,
            element: <AdminUsersListPage />,
          },
          {
            path: "create",
            element: <AdminUserCreatePage />,
          },
        ],
      },
      {
        path: "lots",
        children: [
          {
            index: true,
            element: <LotsListPage />,
          },
          {
            index: false,
            element: <LotDetailPage />,
          }
        ],
      },
      {
        path: "lots/detail/:id",
        children: [
          {
            index: true,
            element: <LotDetailPage />,
          }
        ],
      },
      {
        path: "bills",
        children: [
          {
            index: true,
            element: <BillListPage />,
          },
        ],
      },
      {
        path: "inspections",
        children: [
          {
            index: true,
            element: <InspectionListPage />,
          },
        ],
      },
      {
        path: "inspectionReports",
        children: [
          {
            index: true,
            element: <InspectionReportsListPage />,
          },
        ],
      },
      {
        path: "appraisals",
        children: [
          {
            index: true,
            element: <AppraisalListPage />,
          },
        ],
      },
      {
        path: "codes",
        children: [
          {
            index: true,
            element: <CodeListPage />,
          },
        ],
      },
      {
        path: "errors",
        children: [
          {
            index: true,
            element: <ErrorLogListPage />,
          },
        ],
      },
    ],
  },
];
