import { useQuery } from "@tanstack/react-query";

import { useAxios } from "../use-axios";
import config from "../config";
import { InspectionType } from "src/types/inspection.type";

type GetInspectionsRequest = {
  filters?: {
    query?: string;
  };
  page?: number;
  rowsPerPage?: number;
};

type GetInspectionsResponse = {
  data: InspectionType[];
  count: number;
};

export const useQueryInspections = (request: GetInspectionsRequest) => {
  const { get } = useAxios();

  return useQuery({
    queryKey: ["inspections", request],
    queryFn: async () => {
      const params = {
        address: request.filters?.query,
        size: request.rowsPerPage,
        page: request.page,
      };

      return await get<GetInspectionsResponse>("/admin/inspections", {
        params,
      });
    },
    staleTime: config.staleTime,
  });
};
