import { useQuery } from "@tanstack/react-query";

import { useAxios } from "../use-axios";
import config from "../config";
import type { ContactPeopleType } from "src/types/contact-people.type";

type GetContactPeopleRequest = {
  filters?: {
    query?: string;
  };
  page?: number;
  rowsPerPage?: number;
};

type GetContactPeopleResponse = {
  data: ContactPeopleType[];
  count: number;
};

export const useQueryContactPeople = (request: GetContactPeopleRequest) => {
  const { get } = useAxios();

  return useQuery({
    queryKey: ["contact-people", request],
    queryFn: async () => {
      const params = {
        email: request.filters?.query,
        size: request.rowsPerPage,
        page: request.page,
      };

      return await get<GetContactPeopleResponse>("/admin/contact-peoples", {
        params,
      });
    },
    staleTime: config.staleTime,
  });
};
