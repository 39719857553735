import type { FC, ReactNode } from "react";
import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useAuth, useRouter } from "src/hooks";

interface AuthGuardProps {
  children: ReactNode;
}

export const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const [checked, setChecked] = useState(false);

  const router = useRouter();
  const { isAuthenticated } = useAuth();

  const check = useCallback(() => {
    if (!isAuthenticated) {
      router.replace("/auth/signIn");
    } else {
      setChecked(true);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    check();
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};
