import { useQuery } from "@tanstack/react-query";

import { useAxios } from "../use-axios";
import config from "../config";
import { ContactType } from "src/types/contact.type";

type GetContactRequest = {
  filters?: {
    query?: any;
  };
  page?: number;
  rowsPerPage?: number;
};

type GetContactResponse = {
  data: ContactType[];
  count: number;
};

export const useQueryContact = (request: GetContactRequest) => {
  const { post } = useAxios();

  return useQuery({
    queryKey: ["contact", request],
    queryFn: async () => {
      const params = {
        size: request.rowsPerPage,
        page: request.page,
      };

      return await post<any, GetContactResponse>("/admin/contacts/query",
        {
          filter: {
            ...request.filters?.query,
          }
        },
        {
          params,
        });
    },
    staleTime: config.staleTime,
  });
};
