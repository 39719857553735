import prodConfig from "./production.json";
import stageConfig from "./staging.json";
import devConfig from "./dev.json";

interface Config {
  apiBaseUrl: string;
  environment: string;
}

let config: Config;

switch (process.env.REACT_APP_ENV) {
  case "production":
    config = prodConfig;
    break;
  case "stage":
    config = stageConfig;
    break;
  default:
    config = devConfig;
    break;
}

export default config;
