import { useQuery } from "@tanstack/react-query";

import { useAxios } from "../use-axios";
import config from "../config";
import { BillType } from "src/types/bill.type";

type GetLotsRequest = {
  filters?: {
    query?: string;
  };
  page?: number;
  rowsPerPage?: number;
};

type GetLotsResponse = {
  data: BillType[];
  count: number;
};

export const useQueryBills = (request: GetLotsRequest) => {
  const { get } = useAxios();

  return useQuery({
    queryKey: ["bills", request],
    queryFn: async () => {
      const params = {
        address: request.filters?.query,
        size: request.rowsPerPage,
        page: request.page,
      };

      return await get<GetLotsResponse>("/admin/documents", {
        params,
      });
    },
    staleTime: config.staleTime,
  });
};
