import type { FC } from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import { common } from "@mui/material/colors";

import { usePopover } from "src/hooks/use-popover";
import { AccountPopover } from "./account-popover";
import { useRouter } from "src/hooks";
import { paths } from "src/paths";

export const AccountButton: FC = () => {
  const popover = usePopover<HTMLButtonElement>();
  const router = useRouter();

  const handleLogout = () => {
    sessionStorage.removeItem("accessToken");
    router.replace(paths.auth.signIn);
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "divider",
          height: 40,
          width: 40,
          borderRadius: "50%",
        }}
      >
        <Avatar sx={{ bgcolor: common }}>AD</Avatar>
      </Box>
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={() => {
          popover.handleClose();
          handleLogout();
        }}
        open={popover.open}
      />
    </>
  );
};
