import type { RouteObject } from "react-router";
import { Navigate } from "react-router-dom";

import { dashboardRoutes } from "./dashboard";
import { authRoutes } from "./auth";

import Error404Page from "src/pages/404";
import Error401Page from "src/pages/401";

export const routes: RouteObject[] = [
  ...dashboardRoutes,
  ...authRoutes,
  {
    path: "404",
    element: <Error404Page />,
  },
  {
    path: "401",
    element: <Error401Page />,
  },
  {
    path: "*",
    element: <Error404Page />,
  },
];
