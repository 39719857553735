import type { FC } from "react";

export const Logo: FC = () => {
  return (
    <svg viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_2)">
        <path
          d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z"
          fill="url(#paint0_linear_1_2)"
        />
        <path
          d="M33.1131 7.31383V55.9298L40.8213 49.7989V23.129L33.1131 7.31383Z"
          fill="white"
        />
        <path
          d="M30.805 3.03209V45.6374L19.1521 40.4929V17.3588L30.805 3.03209Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1_2"
          x1="3000"
          y1="0"
          x2="3000"
          y2="6000"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#625393" />
          <stop offset="1" stopColor="#4D4276" />
        </linearGradient>
        <clipPath id="clip0_1_2">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
