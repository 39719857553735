import { useQuery } from "@tanstack/react-query";

import { useAxios } from "../use-axios";
import config from "../config";
import { CodeType } from "src/types/code.type";

type GetCodeRequest = {
  filters?: {
    query?: string;
  };
  page?: number;
  rowsPerPage?: number;
};

type GetCodeResponse = {
  data: CodeType[];
  count: number;
};

export const useQueryCode = (request: GetCodeRequest) => {
  const { get } = useAxios();

  return useQuery({
    queryKey: ["contact", request],
    queryFn: async () => {
      const params = {
        email: request.filters?.query,
        size: request.rowsPerPage,
        page: request.page,
      };

      return await get<GetCodeResponse>("/admin/codes", {
        params,
      });
    },
    staleTime: config.staleTime,
  });
};
