import { useQuery } from "@tanstack/react-query";

import { useAxios } from "../use-axios";
import config from "../config";
import { AdminUserType } from "src/types/admin-user.type";

type GetAdminUsersRequest = {
  page?: number;
  rowsPerPage?: number;
};

type GetAdminUsersResponse = {
  data: AdminUserType[];
  count: number;
};

export const useQueryAdminUsers = (request: GetAdminUsersRequest) => {
  const { get } = useAxios();

  return useQuery({
    queryKey: ["admin-users", request],
    queryFn: async () => {
      const params = {
        size: request.rowsPerPage,
        page: request.page,
      };

      return await get<GetAdminUsersResponse>("/admin/admin-users", {
        params,
      });
    },
    staleTime: config.staleTime,
  });
};
