import type { ReactNode, FC } from "react";
import { createContext, useCallback, useEffect, useReducer } from "react";
import PropTypes from "prop-types";

import { useAuthUser, useLogin } from "src/hooks";

const STORAGE_KEY = "accessToken";

type UserData = {
  email: string;
};

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: UserData | null;
}

enum ActionType {
  INITIALIZE = "INITIALIZE",
  SIGN_IN = "SIGN_IN",
  SIGN_OUT = "SIGN_OUT",
}

type InitializeAction = {
  type: ActionType.INITIALIZE;
  payload: {
    isAuthenticated: boolean;
    user: UserData | null;
  };
};

type SignInAction = {
  type: ActionType.SIGN_IN;
  payload: {
    user: UserData | null;
    isAuthenticated: boolean;
  };
};

type SignOutAction = {
  type: ActionType.SIGN_OUT;
};

type Action = InitializeAction | SignInAction | SignOutAction;

type Handler = (state: State, action: any) => State;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers: Record<ActionType, Handler> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isInitialized: true,
      isAuthenticated,
      user,
    };
  },
  SIGN_IN: (state: State, action: SignInAction): State => {
    const { user, isAuthenticated } = action.payload;

    return {
      ...state,
      isAuthenticated,
      user,
    };
  },
  SIGN_OUT: (state: State): State => {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  },
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export interface AuthContextType extends State {
  signIn: (labName: string, labKey: string) => Promise<void>;
  signOut: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  ...initialState,
  signIn: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const loginService = useLogin();
  const authUserService = useAuthUser();

  const initialize = useCallback(async (): Promise<void> => {
    try {
      const token = window.sessionStorage.getItem(STORAGE_KEY);

      if (token) {
        const user = await authUserService.mutateAsync({ token });

        if (user) {
          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        }
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (e) {
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [dispatch]);

  const signIn = useCallback(
    async (email: string, password: string): Promise<void> => {
      const signInResponse = await loginService.mutateAsync({
        email,
        password,
      });
      const token = signInResponse?.token;

      const user = await authUserService.mutateAsync({ token });
      if (token) {
        window.sessionStorage.setItem(STORAGE_KEY, signInResponse.token);
        dispatch({
          type: ActionType.SIGN_IN,
          payload: {
            ...state,
            isAuthenticated: true,
            user: user,
          },
        });
      } else {
        dispatch({
          type: ActionType.SIGN_IN,
          payload: {
            ...state,
            isAuthenticated: false,
            user: null,
          },
        });
      }
    },
    [dispatch],
  );

  const signOut = useCallback(async (): Promise<void> => {
    window.sessionStorage.removeItem(STORAGE_KEY);

    dispatch({
      type: ActionType.SIGN_OUT,
    });
  }, [dispatch]);

  useEffect(() => {
    initialize().catch(() => {
      // noop
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signOut,
        signIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
