import { useQuery } from "@tanstack/react-query";

import { useAxios } from "../use-axios";
import config from "../config";
import { LotsType } from "src/types/lots.type";
import { DocumentTypeEnums } from "src/types/bill.type";

type GetLotsRequest = {
  filters?: {
    query?: string;
  };
  page?: number;
  rowsPerPage?: number;
};

type GetLotsResponse = {
  data: LotsType[];
  count: number;
};

type UploadRentalStatementBody = {
  lotId: string;
  ownerContactId: string;
  issueDate: string;
  type: DocumentTypeEnums;
}

export const useQueryLots = (request: GetLotsRequest) => {
  const { get } = useAxios();

  return useQuery({
    queryKey: ["lots", request],
    queryFn: async () => {
      const params = {
        address: request.filters?.query,
        size: request.rowsPerPage,
        page: request.page,
      };

      return await get<GetLotsResponse>("/admin/lots", {
        params,
      });
    },
    staleTime: config.staleTime,
  });
};

export const useQueryLotById = (id: string) => {
  const { get } = useAxios();

  return useQuery({
    queryFn: async () => {
      return await get<LotsType>(`/admin/lots/${id}`);
    },
    staleTime: config.staleTime,
  });
};

export const useDocumentApi = () => {
  const { post, get, _delete } = useAxios();

  const getProfileImage = async (lotId: string) => {
    return await get(`/admin/lots/${lotId}/profile-image`);
  }

  const getDocuments = async (lotId: string) => {
    return await get(`/admin/documents/${lotId}`);
  }

  const deleteDocument = (documentId: string) => {
    return _delete(`/admin/documents/${documentId}`);
  }

  const uploadDocuments = async (data: UploadRentalStatementBody, file: any) => {
    const formData = new FormData();
    formData.append('lotId', data.lotId);
    formData.append('ownerContactId', data.ownerContactId);
    formData.append('issueDate', data.issueDate);
    formData.append('type', data.type);
    formData.append('file', file);
    return await post(`/admin/lots/documents/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  };
  return { uploadDocuments, getProfileImage, getDocuments, deleteDocument };
};