import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import type { ErrorType } from "src/types/error";
import { useAxios } from "../use-axios";

type SignInPayload = {
  email: string;
  password: string;
};

type SignInResponse = {
  token: string;
};

export const useLogin = () => {
  const { post } = useAxios();

  return useMutation<SignInResponse, AxiosError<ErrorType>, SignInPayload>(
    async (payload: SignInPayload) =>
      await post<SignInPayload, SignInResponse>("/admin/login", payload),
  );
};
