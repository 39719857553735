import type { ReactNode } from "react";
import { useMemo } from "react";

import { paths } from "src/paths";

export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

export const useSections = () => {
  return useMemo(() => {
    return [
      {
        items: [
          {
            title: "Dashboard",
            path: paths.dashboard.index,
          },
          {
            title: "Admin Users",
            path: paths.dashboard.adminUsers.index,
            items: [
              {
                title: "List",
                path: paths.dashboard.adminUsers.index,
              },
              {
                title: "Create",
                path: paths.dashboard.adminUsers.create,
              },
            ],
          },
          {
            title: "Contact People",
            path: paths.dashboard.contactPeople.index,
            items: [
              {
                title: "List",
                path: paths.dashboard.contactPeople.index,
              },
            ],
          },
          {
            title: "Contacts",
            path: paths.dashboard.contacts.index,
            items: [
              {
                title: "List",
                path: paths.dashboard.contacts.index,
              },
            ],
          },
          {
            title: "Lots",
            path: paths.dashboard.lots.index,
            items: [
              {
                title: "List",
                path: paths.dashboard.lots.index,
              },
            ],
          },
          {
            title: "Bills",
            path: paths.dashboard.bills.index,
            items: [
              {
                title: "List",
                path: paths.dashboard.bills.index,
              },
            ],
          },
          {
            title: "Inspections",
            path: paths.dashboard.inspections.index,
            items: [
              {
                title: "List",
                path: paths.dashboard.inspections.index,
              },
            ],
          },
          {
            title: "InspectionReports",
            path: paths.dashboard.inspectionReports.index,
            items: [
              {
                title: "List",
                path: paths.dashboard.inspectionReports.index,
              },
            ],
          },
          {
            title: "Appraisals",
            path: paths.dashboard.appraisals.index,
            items: [
              {
                title: "List",
                path: paths.dashboard.appraisals.index,
              },
            ],
          },
          {
            title: "Verification code",
            path: paths.dashboard.codes.index,
            items: [
              {
                title: "List",
                path: paths.dashboard.codes.index,
              },
            ],
          },
          {
            title: "Error logs",
            path: paths.dashboard.errors.index,
            items: [
              {
                title: "List",
                path: paths.dashboard.errors.index,
              },
            ],
          },
        ],
      },
    ];
  }, []);
};
