import { useQuery } from "@tanstack/react-query";

import { useAxios } from "../use-axios";
import config from "../config";
import { InspectionReportType } from "src/types/inspection-report.type";

type GetInspectionReportsRequest = {
  filters?: {
    query?: string;
  };
  page?: number;
  rowsPerPage?: number;
};

type GetInspectionReportsResponse = {
  data: InspectionReportType[];
  count: number;
};

export const useQueryInspectionReports = (
  request: GetInspectionReportsRequest,
) => {
  const { get } = useAxios();

  return useQuery({
    queryKey: ["inspection-reports", request],
    queryFn: async () => {
      const params = {
        address: request.filters?.query,
        size: request.rowsPerPage,
        page: request.page,
      };

      return await get<GetInspectionReportsResponse>(
        "/admin/inspection-reports",
        {
          params,
        },
      );
    },
    staleTime: config.staleTime,
  });
};
