export const paths = {
  index: "/dashboard",
  dashboard: {
    index: "/dashboard",
    adminUsers: {
      index: "/dashboard/adminUsers",
      create: "/dashboard/adminUsers/create",
    },
    contactPeople: {
      index: "/dashboard/contactPeople",
    },
    contacts: {
      index: "/dashboard/contacts",
    },
    lots: {
      index: "/dashboard/lots",
      detail: (id: string) => `/dashboard/lots/detail/${id}`,
    },
    bills: {
      index: "/dashboard/bills",
    },
    inspections: {
      index: "/dashboard/inspections",
    },
    inspectionReports: {
      index: "/dashboard/inspectionReports",
    },
    appraisals: {
      index: "/dashboard/appraisals",
    },
    codes: {
      index: "/dashboard/codes",
    },
    errors: {
      index: "/dashboard/errors",
    },
  },
  auth: {
    signIn: "/auth/signIn",
  },
};
